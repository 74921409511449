<template>
    <div class="flex flex-col md:flex-row gap-5 lg:gap-0 items-center justify-around mb-15 lg:mb-24">
        <a :href="'tel:' + phone" class="w-77 h-39.5 bg-[#F3F3F3] col-flex gap-6 px-2">
            <div class="w-16 h-16">
                <v-image option_key="contact_call_footer.global" alt="icon-call" class="w-full h-full" />
            </div>
            <v-content option_key="phone_contact_box.global" class="w-full h-auto"></v-content>
        </a>
        <a :href="'mailto:' + email" class="w-77 h-39.5 bg-[#F3F3F3] col-flex gap-6 px-2">
            <div class="w-16 h-16">
                <v-image option_key="contact_mail_footer.global" alt="icon-mail" class="w-full h-full" />
            </div>

            <v-content option_key="email_contact_box.global" class="w-full h-auto"> </v-content>
        </a>
        <div class="w-77 h-39.5 bg-[#F3F3F3] col-flex gap-6 px-2">
            <div class="w-16 h-16">
                <v-image option_key="contact_person_footer.global" alt="icon-person" class="w-full h-full" />
            </div>
            <v-content option_key="text_contact_box.global"  class="w-full h-auto"></v-content>
        </div>
    </div>
</template>

<script setup lang="ts">
const phone = ref(getDataGlobalByKey('vi-VN', `TEXT__phone_contact_box.global`)?.option_value)
const email = ref(getDataGlobalByKey('vi-VN', `TEXT__email_contact_box.global`)?.option_value)
</script>

<style></style>
